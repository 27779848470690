:root {
    --jumbotron-padding-y: 3rem;
  }
  
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y));
    padding-bottom: calc(var(--jumbotron-padding-y) );
    margin-bottom: 0;
    background-color: #fff;
  }
  @media (min-width: 768px) {
    .jumbotron {
      padding-top: calc(var(--jumbotron-padding-y)  );
      /* padding-bottom: calc(var(--jumbotron-padding-y) * 2.5); */
    }
  }
    
  .jumbotron p:last-child {
    margin-bottom: 0;

  }
  
  .jumbotron-heading {
    font-weight: 300;
  }
  
  .jumbotron .container {
    max-width: 40rem;
  }
  
  footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  footer p {
    margin-bottom: .25rem;
  }
  
  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }